/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                var can_ajax;
                if (Modernizr.history && $("#wpadminbar").length === 0) { // ask Modernizr if the browser supports history and we are not logged in. 
                    can_ajax = true;
                } else {
                    can_ajax = false;
                }
                //////////////////////////////////////////////
                var contentResizeTimer;

                var original_window_width = $(window).width();
                if (navigator.userAgent.match(/Mobi/)) {
                    if (window.innerHeight > window.innerWidth) { // is portrait
                        if ($(window).width() <= 320) { // iphone 5
                            $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.74, minimum-scale=0.74");
                        } else if ($(window).width() <= 360) { // gal s5
                            $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.84, minimum-scale=0.84");
                        } else if ($(window).width() <= 375) { // iphone 6
                            $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.87, minimum-scale=0.87");
                        }

                    } else { // is landscape
                        if ($(window).width() <= 568) { // iphone 5
                            $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.74, minimum-scale=0.74");
                        } else if ($(window).width() <= 640) { // gal s5
                            $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.84, minimum-scale=0.84");
                        } else if ($(window).width() <= 667) { // iphone 6
                            $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.87, minimum-scale=0.87");
                        }
                    }

                }

                var added_viewpot_width = $(window).width() - original_window_width,
                    calc_viewport = function(requested_width) {
                        return requested_width + added_viewpot_width;
                    };

                //calc viewport usage
                /*                if (Modernizr.mq('(max-width: ' + calc_viewport(767) + 'px)')) {
                                    //do stuff
                                }*/


                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                /* GSAP image slider homepage */
                var SURGE_GSAP_image_slider = function(setting) {
                    var slider = $(setting.slider);
                    if (slider.length) {
                        var tl = setting.timeline,
                            slides_in_group = setting.slides_in_group,
                            slider_inner = $(".SURGE_gsap_inner", slider),
                            slider_slides = $("> div", slider_inner),
                            slide_buttons = $(".SURGE_slide_buttons", slider),
                            switch_buttons = $(".slide_switch_buttons", slider),
                            number_of_slides_minus_one = slider_slides.length - 1,
                            number_of_slides = slider_slides.length,
                            show_duration = setting.show_duration,
                            animation_duration = setting.animation_duration,
                            do_each_loop = true,
                            x = 0,
                            switches = "";
                        $.each(setting.slides_in_group.min_width, function(key, val) {
                            if (Modernizr.mq('(min-width: ' + calc_viewport(parseInt(key, 10)) + 'px)')) {
                                slides_in_group = setting.slides_in_group.min_width[key];
                                //console.log("found in min_width: "+slides_in_group);   
                                return false;
                            }
                        });
                        $.each(setting.slides_in_group.max_width, function(key, val) {
                            if (Modernizr.mq('(max-width: ' + calc_viewport(parseInt(key, 10)) + 'px)')) {
                                slides_in_group = setting.slides_in_group.max_width[key];
                                //console.log("found in max_width: "+slides_in_group);    
                                return false;
                            }
                        });
                        var number_of_times_to_do_slide_animation = Math.floor(number_of_slides / slides_in_group);
                        $(slider_slides).css("width", 100 / slides_in_group + "%");
                        /*if (setting.animation === "fade") {
                            theSplitText = new SplitText($(".text_box_main_title h3", slider), { type: "words, chars" });
                            TweenLite.set($(".text_box_main_title h3", slider), { perspective: 400, autoAlpha: 1.0 });
                        }*/
                        if (setting.animation === "slide") {
                            if (number_of_slides % slides_in_group === 0) {
                                number_of_times_to_do_slide_animation--;
                            }
                        } else {
                            number_of_times_to_do_slide_animation--;
                        }
                        for (var i = number_of_times_to_do_slide_animation; i >= 0; i--) {
                            switches = switches + "<div></div>";
                        }
                        $("> div:nth-child(" + slides_in_group + "n)", slider_inner).css("background-image", "none");
                        $("> div", slider_inner).last().css("background-image", "none");
                        $(".slide_switch_buttons", slider).html(switches);
                        var slide_switch_buttons = $(slider).find(".slide_switch_buttons div");
                        $.each(slider_slides, function(i, e) {
                            if (typeof slides_in_group !== "undefined") {
                                if (i % slides_in_group === 1 || slides_in_group === 1) {
                                    do_each_loop = true;
                                } else {
                                    do_each_loop = false;
                                }
                            }
                            if (do_each_loop) {
                                x++;
                                var switch_index = x - 1;
                                //if (setting.animation === "fade") {
                                tl
                                    .add("start" + x)
                                    .set(slide_switch_buttons, {
                                        background: "#ffffff"
                                    }, "start" + x)
                                    .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
                                        background: "#6C7574"
                                    }, "start" + x);
                                //}
                                if (setting.animation === "fade") {
                                    tl
                                    //.add("start" + x)
                                        .set(slider_inner, {
                                            autoAlpha: 0.2
                                        }, "start" + x)
                                        .to(slider_inner, animation_duration, {
                                            autoAlpha: 1.0
                                        }, "start" + x);
                                }
                                // animations that happen after the full slide has faded in. e.g text animations
                                if (setting.type === "main slider") {
                                    tl
                                        .add("start_inner_animation" + x)
                                        .set($(".text_box_text_body", this), {
                                            autoAlpha: 0.0,
                                            y: "-=40"
                                        }, "start" + x) // this is purposely set to the last label so that it is hidden before the full slide comes in
                                        .to($(".text_box_text_body", this), animation_duration, {
                                            autoAlpha: 1.0,
                                            y: "+=40",
                                            ease: Power3.easeOut
                                        }, "start_inner_animation" + x)
                                        .staggerFrom($(".text_box_main_title h3 div div", this), animation_duration, {
                                            rotationX: 0,
                                            x: -250,
                                            transformOrigin: "0% 50% -50",
                                            scale: 9,
                                            autoAlpha: 0.0,
                                            ease: Back.easeOut
                                        }, 0.06, "start_inner_animation" + x + "+=" + animation_duration);
                                }
                                // a label to fill the gap so we can understand what's happening when using currentLabel() later on in the hover function
                                tl
                                    .add("not_animating_just_showing_slide" + x)
                                    .set({}, {}, "not_animating_just_showing_slide" + x + "+=" + show_duration);
                                // start removing slide after the amount of seconds stored in show_duration (from last animation)
                                if (setting.animation === "fade") {
                                    tl
                                        .add("start_removing_slide" + x)
                                        .to(slider_inner, animation_duration, {
                                            autoAlpha: 0.2
                                        }, "start_removing_slide" + x)
                                        .set(slider_inner, {
                                            x: "-=100%"
                                        }, "start_removing_slide" + x + "+=" + animation_duration);
                                }
                                if (setting.animation === "slide" && x <= number_of_times_to_do_slide_animation) {
                                    tl
                                        .set($(slide_switch_buttons).eq(switch_index), {
                                            background: "#6C7574"
                                        }, "start_removing_slide" + x)
                                        .set(slide_switch_buttons, {
                                            background: "#ffffff"
                                        }, "start_removing_slide" + x)
                                        .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
                                            background: "#6C7574"
                                        }, "start_removing_slide" + x)
                                        .add("start_removing_slide" + x)
                                        .to(slider_inner, animation_duration, {
                                            x: "-=100%"
                                        }, "start_removing_slide" + x);
                                }
                                if (setting.animation === "slide" && x === number_of_times_to_do_slide_animation) {
                                    switch_index = switch_index + 1;
                                    tl
                                        .set($(slide_switch_buttons).eq(switch_index), {
                                            background: "#6C7574"
                                        }, "start_removing_slide" + x)
                                        .set(slide_switch_buttons, {
                                            background: "#ffffff"
                                        }, "start_removing_slide" + x)
                                        .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
                                            background: "#6C7574"
                                        }, "start_removing_slide" + x);
                                }
                            }
                        });
                        var hover_slider,
                            all_slide_btns = $.merge(slide_buttons, switch_buttons),
                            current_label,
                            intervaHandle;
                        $(slider).hover(function() {
                            if (intervaHandle) {
                                clearInterval(intervaHandle);
                                intervaHandle = null;
                            }
                            intervaHandle = setInterval(function() {
                                current_label = tl.currentLabel();
                                //console.log(current_label);
                                if (current_label.indexOf("not_animating_just_showing_slide") >= 0) {
                                    clearInterval(intervaHandle);
                                    intervaHandle = null;
                                    tl.pause();
                                }
                            }, 200);
                            if (typeof hover_slider !== "undefined") {
                                hover_slider.kill();
                            }
                            hover_slider = new TimelineMax();
                            hover_slider.to(all_slide_btns, 1.0, {
                                autoAlpha: 1.0,
                                zIndex: 2,
                                ease: Power3.easeOut
                            });
                        }, function() {
                            if (intervaHandle) {
                                clearInterval(intervaHandle);
                                intervaHandle = null;
                            }
                            tl.play();
                            if (typeof hover_slider !== "undefined") {
                                hover_slider.kill();
                            }
                            hover_slider = new TimelineMax();
                            hover_slider.to(all_slide_btns, 1.0, {
                                autoAlpha: 0.0,
                                zIndex: -1,
                                ease: Power3.easeOut
                            }).set(all_slide_btns, { zIndex: -1 });
                        });
                        $(".slide_switch_buttons div, .SURGE_slide_buttons", slider).on("click", function() {
                            var to_time, current_label = tl.currentLabel(),
                                time, current_label_number = parseInt(current_label.match(/\d+/), 10),
                                purpusly_undefined; // http://stackoverflow.com/a/9282974/2129024;
                            // no way other than checking the transform percentage directly
                            if (intervaHandle) {
                                clearInterval(intervaHandle);
                                intervaHandle = null;
                            }
                            //console.log(current_label);
                            if (current_label.indexOf("start_inner_animation") >= 0) {
                                //only stop if there are active animations.
                                tl.pause();
                                time = tl.time();
                            }
                            if ($(this).is(".slide_switch_buttons div")) {
                                var index = $(this).index();
                                index = index + 1;
                                to_time = tl.getLabelTime("not_animating_just_showing_slide" + index);
                                if (setting.animation === "slide" && $(this).closest(".slide_switch_buttons").find("div").length === index) {
                                    to_time = tl.endTime();
                                }
                                TweenMax.to(tl, 0.0, {
                                    time: to_time
                                });
                                return false;
                            }
                            var transformMatrix = slider_inner.css("-webkit-transform") ||
                                slider_inner.css("-moz-transform") ||
                                slider_inner.css("-ms-transform") ||
                                slider_inner.css("-o-transform") ||
                                slider_inner.css("transform");
                            var matrix = transformMatrix.replace(/[^0-9\-.,]/g, '').split(',');
                            var translate_x = matrix[12] || matrix[4]; //translate x
                            //var translate_y = matrix[13] || matrix[5]; //translate y
                            //console.log();
                            var abs_translate_x = Math.abs(translate_x);
                            //console.log(abs_translate_x);
                            var slider_width = $(slider_inner).width();
                            /////////////////
                            var time_line_label, max_possible_abs_translate_x, take_one_slide_off_max_abs_translate_x;
                            if ($(this).is(".SURGE_go_forward")) {
                                if (current_label.indexOf("start") >= 0 || current_label.indexOf("start_inner_animation") >= 0 || current_label.indexOf("not_animating_just_showing_slide") >= 0) {
                                    from_label = "start_removing_slide" + current_label_number;
                                    last_plus_one = parseInt(current_label.match(/\d+/), 10) + 1;
                                    if (last_plus_one > number_of_slides && setting.animation === "fade") {
                                        tl.tweenFromTo("start", "not_animating_just_showing_slide1");
                                    } else if (last_plus_one > number_of_times_to_do_slide_animation && setting.animation === "slide") {
                                        max_possible_abs_translate_x = number_of_times_to_do_slide_animation * slider_width;
                                        take_one_slide_off_max_abs_translate_x = number_of_times_to_do_slide_animation * slider_width - slider_width;
                                        if (max_possible_abs_translate_x === abs_translate_x) { // its all the way to the end                                            
                                            // back to the start
                                            TweenMax.to(tl, 0.0, {
                                                time: 0
                                            });
                                        } else {
                                            tl.tweenFromTo(from_label, purpusly_undefined);
                                        }
                                    } else {
                                        to_label = "not_animating_just_showing_slide" + last_plus_one;
                                        tl.tweenFromTo(from_label, to_label);
                                    }
                                }
                            }
                            if ($(this).is(".SURGE_go_back")) {
                                //if (current_label.indexOf("start") >= 0 || current_label.indexOf("start_inner_animation") >= 0 || current_label.indexOf("not_animating_just_showing_slide") >= 0) {
                                from_label = "not_animating_just_showing_slide" + current_label_number;
                                last_minus_one = parseInt(current_label.match(/\d+/), 10) - 1;
                                if (setting.animation === "slide") {
                                    if (current_label_number > 1 && current_label_number !== number_of_times_to_do_slide_animation) {
                                        // must be a middle slide
                                        tl.tweenFromTo("not_animating_just_showing_slide" + current_label_number, "not_animating_just_showing_slide" + last_minus_one); // take it back to the last slide
                                    } else {
                                        if (typeof translate_x === "undefined") { // has not slide yet
                                            //console.log("1");
                                            tl.tweenFromTo(purpusly_undefined, purpusly_undefined);
                                        } else if (abs_translate_x === 0) { // has slide but has came back to first slide - so its now set
                                            //console.log("2");
                                            tl.tweenFromTo("start_removing_slide" + number_of_times_to_do_slide_animation, purpusly_undefined);
                                        } else if (abs_translate_x < slider_width) { // has started to slide to the second slide but has not yet got the full way
                                            //console.log("3");
                                            tl.tweenFromTo(tl.time(), 0); // take it back to the start
                                        } else if (current_label_number === number_of_times_to_do_slide_animation) { // it could be the last or the second last slide
                                            //console.log("4");
                                            max_possible_abs_translate_x = number_of_times_to_do_slide_animation * slider_width;
                                            take_one_slide_off_max_abs_translate_x = number_of_times_to_do_slide_animation * slider_width - slider_width;
                                            if (max_possible_abs_translate_x === abs_translate_x) { // its all the way to the end
                                                //console.log("5");
                                                tl.tweenFromTo(tl.time(), "not_animating_just_showing_slide" + current_label_number); // go back one but keep it on this end loop
                                            } else if (abs_translate_x < max_possible_abs_translate_x && abs_translate_x !== take_one_slide_off_max_abs_translate_x) { // its moving to wards the end but not yet all the way to it
                                                //console.log("6");
                                                tl.tweenFromTo(tl.time(), "not_animating_just_showing_slide" + current_label_number);
                                            } else if (abs_translate_x === take_one_slide_off_max_abs_translate_x) { // its waiting on the second last slide
                                                //console.log("7");
                                                tl.tweenFromTo("not_animating_just_showing_slide" + current_label_number, "not_animating_just_showing_slide" + last_minus_one); // take it back to the last slide
                                            }
                                        }
                                    }
                                } else { // is fade
                                    if (last_minus_one === 0) {
                                        tl.tweenFromTo("start" + number_of_slides, purpusly_undefined);
                                    } else {
                                        to_label = "not_animating_just_showing_slide" + last_minus_one;
                                        if (time) {
                                            //console.log("time: " + time + " | to_label: " + to_label);
                                            tl.tweenFromTo(time, to_label);
                                        } else {
                                            //var from_label_time = tl.getLabelTime(from_label);
                                            var to_label_time = tl.getLabelTime(to_label);
                                            //console.log("from_label_time: " + from_label_time + " | to_label_time: " + to_label_time);
                                            // not using this because to sometimes failed to go all the way back.                                    
                                            //tl.tweenFromTo(from_label_time, to_label_time);
                                            /* or this */
                                            /*                                  var from_label_time = tl.getLabelTime(from_label);
                                                                                var to_label_time = tl.getLabelTime(to_label);
                                                                                var inner_last_from_label_time = tl.getLabelTime("start_inner_animation"+last_minus_one);
                                                                                var length_of_start_inner_animation = from_label_time - inner_last_from_label_time;
                                                                                var detuct_amount = length_of_start_inner_animation / 10;
                                                                                detuct_amount = length_of_start_inner_animation - detuct_amount;
                                                                                var final = inner_last_from_label_time + detuct_amount; 
                                                                                console.log("from_label_time: " + final + " | to_label_time: " + to_label_time);
                                                                                tl.tweenFromTo(final, to_label_time);
                                                                                */
                                            TweenMax.to(tl, 0.0, {
                                                time: to_label_time
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    }
                };
                /* END GSAP image slider homepage */

                // center things
                TweenMax.set(".center_me", {
                    xPercent: -50,
                    yPercent: -50
                });

                /* load images after page load */
                $(document).ready(function() {
                    var do_images = function() {
                        var setURL;
                        $.each($(".SURGE_iamge_swap"), function() {
                            current_URL = $(this).attr('src');
                            if (Modernizr.mq('(max-width: ' + calc_viewport(991) + 'px)')) {
                                setURL = $(this).attr('data-mobile');
                                if (typeof setURL === typeof undefined) {
                                    setURL = $(this).attr('data-desktop');
                                }
                            } else {
                                setURL = $(this).attr('data-desktop');
                            }
                            if (typeof current_URL === typeof undefined) {
                                // must be fist go - is div - so convert it to an image
                                $(this).replaceWith(function() {
                                    return $("<img>", {
                                        'class': this.className,
                                        src: setURL,
                                        alt: $(this).attr('alt'),
                                        'data-desktop': $(this).attr('data-desktop'),
                                        'data-mobile': $(this).attr('data-mobile')
                                    });
                                });
                            } else {
                                if (current_URL !== setURL) {
                                    $(this).attr("src", setURL);
                                }
                            }
                        });
                    };
                    $(window).on("resize", do_images);
                    do_images();
                });

                /* GSAP Slider Initialization */

                var main_slider = new SURGE_GSAP_image_slider({
                    slider: "#SURGE_GSAP_slider1",
                    show_duration: 12,
                    timeline: new TimelineMax({
                        repeat: -1,
                        repeatDelay: 0
                    }),
                    type: "main slider",
                    slides_in_group: 1,
                    animation: "fade", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })                       
                    animation_duration: 0.8
                });

                var grouped_slider = new SURGE_GSAP_image_slider({
                    slider: "#SURGE_GSAP_slider2",
                    show_duration: 5,
                    timeline: new TimelineMax({
                        repeat: -1,
                        repeatDelay: 2.5,
                        yoyo: true // when at the end of the tl reverse back
                    }),
                    type: "grouped slider",
                    slides_in_group: {
                        min_width: {
                            "1200": 4
                        },
                        max_width: {
                            "1199": 3,
                            "991": 3,
                            "767": 2
                        }
                    },
                    animation: "slide", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })                        
                    animation_duration: 1.0
                });

                ///////////////////////////////////////////////////////////////////////////////////////////////////////

                /* Mega menu */
                var mega,
                    menu_number = "#" + $(".SURGE_mega_menu").attr("id");
                mega_open = function(mega_this) {
                        if (!$(mega_this).is(".open")) {
                            if (typeof mega !== "undefined") {
                                mega.kill();
                            }
                            var mega_menu_height = $(".mega_dropdown", mega_this).outerHeight(true);
                            mega = new TimelineMax({ delay: 0.0 });
                            mega.set($("span.parent_text", mega_this), {
                                    background: "#6fa1c8"
                                }, 0)
                                .set($("> a, > span", mega_this), {
                                    color: "#FFF"
                                }, 0);
                            if (Modernizr.mq('(min-width: ' + calc_viewport(767) + 'px)')) {
                                mega.set($(".mega_dropdown", mega_this), {
                                    height: "0px",
                                    autoAlpha: 0.0
                                }, 0);
                            } else {
                                mega.set($(".mega_dropdown", mega_this), {
                                        height: "0px",
                                        autoAlpha: 0.0,
                                        position: "relative",
                                        top: "0"
                                    }, 0)
                                    .set($(mega_this).prev(".close_menu"), {
                                        y: 125
                                    }, 0)
                                    .set($(mega_this).closest(".SURGE_mega_menu"), {
                                        height: "inherit"
                                    }, 0)
                                    .to($(mega_this).prev(".close_menu"), 0.5, {
                                        y: 21,
                                        autoAlpha: 1.0,
                                        display: "block",
                                        rotation: 180
                                    }, 0);
                            }
                            mega.to($(".mega_dropdown", mega_this), 0.5, {
                                zIndex: "9",
                                height: mega_menu_height,
                                autoAlpha: 1.0,
                                ease: Expo.easeOut
                            }, 0);
                            $(mega_this).addClass("open");
                        }
                    },
                    mega_close = function(mega_this) {
                        $(mega_this).removeClass("open");
                        if (typeof mega !== "undefined") {
                            mega.kill();
                        }
                        mega = new TimelineMax({ delay: 0.0 });
                        mega.set($.merge($("span.parent_text, > a, > span, .mega_dropdown", mega_this), $(mega_this).prev(".close_menu")), { clearProps: "all" });
                    };
                var count_this = 0,
                    mega_this,
                    document_width;
                $(document).ready(function() {
                    document_width = $(window).width();
                });
                var mega_events = function() {
                    if (document_width !== $(window).width()) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
                        document_width = $(window).width();
                        TweenMax.set($(menu_number), { clearProps: "height" });
                        mobile_menu_height = $(menu_number).outerHeight(true);
                        //alert("in mega_events");
                        mega_close(".is_dropdown");
                        //if (!navigator.userAgent.match(/Mobi/)) {
                        if (Modernizr.mq('(min-width: ' + calc_viewport(767) + 'px)')) {
                            $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once                           
                            $('.is_dropdown').unbind("mouseenter");
                            $('.is_dropdown').unbind("mouseleave");
                            $('.is_dropdown')
                                .mouseenter(function() {
                                    mega_this = this;
                                    //if (count_this === 0) {
                                    mega_open(mega_this);
                                    //}
                                    //count_this++;
                                    //alert("mouseenter");
                                    if (Modernizr.mq('(min-width: ' + calc_viewport(1199) + 'px)')) {
                                        // code that will put the dropdown roughly below its hovered parent li
                                        var position = $(mega_this).position();
                                        var rightOffset = $(mega_this).closest(".SURGE_mega_menu").width() - (position.left + $(mega_this).width());
                                        //console.log("rightOffset: "+rightOffset);
                                        //console.log("position.left: "+position.left);
                                        var difference = (position.left - rightOffset) + ($(mega_this).width() / 2);
                                        difference = Math.abs(difference); // removing negative -
                                        //console.log("difference: "+difference);
                                        if (difference < 150) {
                                            $(".mega_dropdown", mega_this).css({ left: "17%", right: "17%" });
                                        } else if (position.left > rightOffset) {
                                            $(".mega_dropdown", mega_this).css({ left: "28%", right: "2.3%" });
                                        }
                                        //console.log("menu width: "+ $(".mega_dropdown", this).width());
                                    }
                                })
                                .mouseleave(function() {
                                    //alert("mouseleave");
                                    mega_this = this;
                                    mega_close(mega_this);
                                });
                        } else {
                            $('.is_dropdown').unbind("mouseenter");
                            $('.is_dropdown').unbind("mouseleave");
                            $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once
                            $('.is_dropdown .parent_text').on("click.mega_events", function() {
                                //alert("click");
                                mega_close(".is_dropdown");
                                mega_this = $(this).closest(".is_dropdown");
                                if (!$(mega_this).is(".open")) {
                                    mega_open(mega_this);
                                    goToByScroll(mega_this, 40, 0.8);
                                }
                            });
                        }
                    }
                };
                mega_events();
                $(window).on("resize", mega_events);
                var mobile_menu, mobile_menu_height = $(menu_number).outerHeight(true);
                $('.close_menu, .navbar-toggle').on("click", function() {
                    mega_close($(".is_dropdown"));
                    if ($(this).is(".navbar-toggle")) {
                        if (Modernizr.mq('(max-width: ' + calc_viewport(767) + 'px)')) {
                            if ($(this).hasClass("collapsed")) {
                                if (typeof mobile_menu !== "undefined") {
                                    mobile_menu.kill();
                                }
                                console.log(menu_number);
                                mobile_menu = new TimelineMax({ delay: 0.0 });
                                mobile_menu.set($(menu_number), {
                                        height: "0",
                                        margin: "-23px 0",
                                        position: "relative"
                                    }, 0)
                                    .to($(menu_number), 1.0, {
                                        height: mobile_menu_height,
                                        margin: "0",
                                        autoAlpha: 1.0,
                                        ease: Expo.easeOut
                                    });
                                $(this).removeClass("collapsed");
                            } else {
                                if (typeof mobile_menu !== "undefined") {
                                    mobile_menu.kill();
                                }
                                mobile_menu = new TimelineMax({ delay: 0.0 });
                                mobile_menu.to($(menu_number), 1.0, {
                                    height: "0",
                                    margin: "-23px 0",
                                    autoAlpha: 0.0,
                                    ease: Expo.easeOut
                                });
                                $(this).addClass("collapsed");
                            }
                        }
                    }
                    if ($(this).is(".close_menu")) {
                        goToByScroll("html", 0, 0.8);
                    }
                });
                $(window).load(function() { // wait for images to load
                    // set cols the same height
                    var highest = null,
                        hi = 0,
                        collection = $(),
                        process_collection = false,
                        remove_hrs = true;
                    if (Modernizr.mq('(min-width: ' + calc_viewport(991) + 'px)')) {
                        $(".mega_col").each(function() {
                            if ($(this).next().is("hr")) {
                                collection = $.merge(collection, $(this));
                                $(this).css({ "padding-right": "0", "background": "none" });
                                process_collection = true;
                            } else {
                                collection = $.merge(collection, $(this));
                            }
                            if (process_collection) {
                                $(collection).each(function(i, el) {
                                    if ($("> *", this).first().is("h4")) {
                                        remove_hrs = false;
                                    }
                                    ////
                                    var h = $(this).outerHeight(true);
                                    if (h > hi) {
                                        hi = h;
                                    }
                                });
                                if (remove_hrs) {
                                    $("> hr:first-of-type", collection).remove();
                                    hi = hi - 25;
                                }
                                $(collection).height(hi);
                                // resets
                                collection = $();
                                process_collection = false;
                                remove_hrs = true;
                                hi = 0;
                            }
                        });
                    } else {
                        $(".mega_dropdown > hr").remove();
                    }
                    // remove end hr(s)
                    //$(".mega_col hr").last().remove();
                    $(".mega_dropdown hr:last-child").remove();
                });
                // set top level label wrappers to the size of the text within them so that they dont jiggle about on hover (when the text goes bold it will make the text bigger)
                // this will stop the text from pushing on the next one
                if (Modernizr.mq('(min-width: ' + calc_viewport(767) + 'px)')) {
                    $(".SURGE_mega_menu .parent_li").each(function() {
                        $(this).width($(this).width());
                        $(".parent_text", this).attr("data-original_width", $(".parent_text", this).width());
                    });
                    $('.parent_li') // here we are takeing the increase of width from the padding on the text element to stop it overflowing its parent
                        .mouseenter(function() {
                            var current_side_padding = $(".parent_text", this).css("padding-left").replace("px", ""), // will be the same as padding right...
                                hover_parent_text_width = $(".parent_text", this).width(),
                                origin_width = $(".parent_text", this).attr("data-original_width"),
                                added_with = hover_parent_text_width - origin_width,
                                added_with_divided = added_with / 2;
                            new_side_padding = current_side_padding - added_with_divided;
                            $(".parent_text", this).css({ "padding-left": new_side_padding + "px", "padding-right": new_side_padding + "px" });
                        })
                        .mouseleave(function() {
                            TweenMax.set($(".parent_text", this), { clearProps: "all" });
                        });
                }
                /* END mega menu */

                function init() {
                    // Do this when a page loads.                    
                    $("body").css("cursor", "auto");
                    if (document.readyState === "complete") {
                        //console.log("page has allready loaded so .ajax() got us here.");                       
                        $(window).off("scroll", arrow_controlls);
                        $(window).scroll(side_nav_move);
                        // $(window).scroll(detect_play);
                        hard_load_and_ajax_page_change();
                    } else {
                        //console.log("was a hard land");
                        $(document).ready(function() {
                            stuff_to_do_on_load();
                        });
                    }
                }

                $(".navigate_block").on("click", function() {
                    $(".sidebar_link").slideToggle( "slow", function() {
                        // Animation complete.
                      });
                });
                
                function goToByScroll(location, offset_top, speed) {
                    if (typeof scroll_animation !== "undefined") {
                        scroll_animation.kill();
                    }
                    scroll_animation = new TimelineMax();
                    scroll_animation.to($(window), speed, {
                        scrollTo: {
                            y: location,
                            offsetY: offset_top,
                            autoKill: true
                        },
                        ease: Power3.easeOut
                    });
                }

                // Code for fixing example.dev:3000 issue. 
                if (window.location.href.indexOf("ashbank.dev") > -1) { // if we are on example.dev
                    $("a:not(figure a)").on("click", function() { //not() can be used to stop it breaking over a hrefs like photo swipe gallery.
                        var currentURL = $(this).attr("href");
                        if (currentURL.toLowerCase().indexOf("wp-admin") > -1 || currentURL.toLowerCase().indexOf("logout") > -1 || currentURL.toLowerCase().indexOf("wp-content") > -1) {
                            // if user clicks dashboard links from front end remove the port so it will not redirect to the dash with the 3000
                            currentURL = currentURL.replace(":3000", "");
                            window.location.href = currentURL;
                            return false;
                        } else if (currentURL.toLowerCase().indexOf("ashbank.dev") > -1 && currentURL.toLowerCase().indexOf(":3000") < 0) {
                            // if on example.dev and is frontend (not admin page) and the port is not already set then add the port
                            currentURL = currentURL.replace(document.location.hostname, document.location.hostname + ":3000");
                            window.location.href = currentURL;
                            return false;
                        }
                    });
                }
                // END ALL
                // listing pages
                // fix headings 
                $(".listing_text_area p")
                    .contents()
                    .filter(function() {
                        return this.nodeType === 3 && $(this).text().trim() === "Location" && $(this).next().is("br") && $(this).next().next().is("br");
                    })
                    .wrap("<h3></h3>");

                $(".listing_text_area p")
                    .contents()
                    .filter(function() {
                        return this.nodeType === 3 && $(this).text().trim() === "Description" && $(this).next().is("br") && $(this).next().next().is("br");
                    })
                    .wrap("<h3></h3>");

                $(".listing_text_area p")
                    .contents()
                    .filter(function() {
                        return this.nodeType === 3 && $(this).text().trim() === "Summary" && $(this).next().is("br") && $(this).next().next().is("br");
                    })
                    .wrap("<h3></h3>");

                $(".listing_text_area p")
                    .contents()
                    .filter(function() {
                        return this.nodeType === 3 && $(this).text().trim() === "Specification" && $(this).next().is("br") && $(this).next().next().is("br");
                    })
                    .wrap("<h3></h3>");

                $.each($(".listing_text_area p h3"), function() {
                    if ($(this).prev().is("br")) {
                        $(this).prev().remove();
                    }
                    if ($(this).next().is("br")) {
                        $(this).next().remove();
                    }
                });

                // image slider with thumbs
                $.each($(".picGall_images div"), function() {
                    $(this).attr("style", $(this).attr("data-style")).removeAttr("data-style");
                });
                $(document).on("click", ".picGall_images .small_image, .picGall .glyphicon-menu-left, .picGall .glyphicon-menu-right", function() {
                    var my_this = this;
                    $(".picGall_images div").eq(0).fadeTo(180, 0.12);
                    setTimeout(function() {
                        if ($(my_this).is(".small_image")) {
                            $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(my_this).addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                        }
                        if ($(my_this).is(".glyphicon-menu-left")) {
                            if ($(".picGall_images .chris_active").prev().is(".big_image")) {
                                $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(".picGall_images div").last().addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                            } else {
                                $(".picGall_images div").eq(0).replaceWith($(".picGall_images .chris_active").removeClass("chris_active").prev().addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                            }
                        }
                        if ($(my_this).is(".glyphicon-menu-right")) {
                            if ($(".picGall_images .chris_active").is(':last-child')) {
                                $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(".picGall_images div").eq(1).addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                            } else {
                                var new_active = $(".picGall_images .chris_active").index() + 1;
                                $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(".picGall_images div").eq(new_active).clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                                $(".picGall_images div").eq(new_active).addClass("chris_active");
                            }
                        }
                        $(".picGall_images div").eq(0).fadeTo(180, 1.00);
                    }, 270);
                });
                // end listing cats
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
